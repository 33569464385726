.menuNuevaCampana{
    border: 1px solid black;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    width: 400px;
    
}

.menuNuevaCampana form{
    padding: 10px;
}


.menuNuevaCampana form textarea {
    width: 350px;
    height: 2.5em;
    border-left: 1px solid #000; /* Ajusta el color y el grosor según sea necesario */
    border-radius: 5px;
    padding: 3px;
    resize: none; /* Evita que se redimensione manualmente */
    overflow-y: visible; /* Añade barra de desplazamiento vertical si el contenido es más grande que el textarea */
}
