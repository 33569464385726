.navbar{
  width: 100%;
  max-width: 100%;
  min-height: 6vh;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color:rgb(192,222,224);
  background-color: rgb(4,67,74);
  transition: all .2s ease;
}


/* Estilos para la barra de navegación en dispositivos móviles */
@media only screen and (max-width: 600px) {
  .navbar {
    width: auto;
    overflow: scroll;
  }
}

.navbarp{
 
  width: 100%;
  max-width: 100%;
  min-height: 6vh;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #999;
  background-color: rgb(53, 131, 141);
  transition: all .2s ease;
}

.nav-item{
  color: #BFCEDE;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.nav-item:hover{
  color: white !important;

}

.nav-active{
  color: white !important;

}

.fuera{
  display: none;
}
.scrolled{
  position: fixed;
  top: 0;
  left: 0;
  background-color:  rgb(53, 131, 141);
  z-index: 10;
}
.navbar-logo{
  width: 200px;
}

.logo

  button{
    
    text-decoration: none;
    color: inherit;
    &:hover{
      color: white;
    }
  
}

.navigation{
  
  ul{
    box-sizing: border-box;
    list-style-type: none;
    display: flex;
    min-height: 6vh;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 0;

    li{
      text-align: center;
     /* padding: .5em 58px .5em 58px;*/
      cursor: pointer;


      &:hover{
        color: white;
      }
    }

    li a{
      text-decoration: none;
      color: inherit;
      
    }

    
    li button{
      text-decoration: none;
    }

  }
}

.sobreAbierto{
padding-bottom: 2px;
 
}

.navbar-ppal{
background-color:rgb(4,67,74)
}

.navbar-ppal .navbar-brand {
  color: #fff;
}

.navbar-ppal .navbar-brand:hover, .navbar-ppal .navbar-brand:focus {
  color: #fff;
}

.navbar-ppal .navbar-nav .nav-link {
  color: #6DA8B0;
}

.navbar-ppal .navbar-nav .nav-link:hover, .navbar-ppal .navbar-nav .nav-link:focus {
  color: rgba(195, 237, 253, 0.75);
}

.navbar-ppal .navbar-nav .nav-link.disabled {
  color: rgba(195, 237, 253, 0.25);
}

.navbar-ppal .navbar-nav .show > .nav-link,
.navbar-ppal .navbar-nav .active > .nav-link,
.navbar-ppal .navbar-nav .nav-link.show,
.navbar-ppal .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-ppal .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-ppal .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-ppal .navbar-text a {
  color: #fff;
}

.navbar-ppal .navbar-text a:hover, .navbar-ppal .navbar-text a:focus {
  color: #fff;
}
