@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);

$color-alpha: #FFFFFF;

* {
  box-sizing: border-box;
  &:before, &:after {
    box-sizing: border-box;
  }
}

h1{
  color:#000
}

html, body {
  height: 100%;
  
 
}

h1 {
  font-weight: 700;
  margin-bottom: 0.5em;
}

.box {
  font-family: 'Open Sans';
  font-size: 18px;
  color: #fff;
  margin-top: 10%;
  margin-left: 50%;
  transform: translate3d(-50%, -50%, 0);
  background-color: rgba(4,67,74, 0.6);
  width: 100%;
  max-width: 600px;
  padding: 5px;
  border: 2px solid $color-alpha;
  &:before, &:after {
    content: "•";
    position: absolute;
    width: 14px;
    height: 14px;
    font-size: 14px;
    color: $color-alpha;
    border: 2px solid $color-alpha;
    line-height: 12px;
    top: 5px;
    text-align: center;
  }
  &:before {
    left: 5px;
  }
  &:after {
    right: 5px;
  }
  .box-inner {
    position: relative;
    border: 2px solid $color-alpha;
    padding: 40px;
    &:before, &:after {
      content: "•";
      position: absolute;
      width: 14px;
      height: 14px;
      font-size: 14px;
      color: $color-alpha;
      border: 2px solid $color-alpha;
      line-height: 12px;
      bottom: -2px;
      text-align: center;
    }
    &:before {
      left: -2px;
    }
    &:after {
      right: -2px;
    }
  }
}