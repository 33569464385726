.App {
  font-family: sans-serif;
  text-align: center;
}

body {
  position: relative;
  font-size: 1rem;
}

button {
  font-size: 1rem;
  padding: 5px;
  cursor: pointer;
  margin-right: 5px;
  margin-bottom: 5px;
}

#dice-box,
#dice-box canvas {
  position: fixed;
  pointer-events: none;
  z-index: 100;
  width: 100%;
  height: 100%;
  
}

.buttonList {
  display: grid;
  grid-template-columns: auto auto 1fr;
  column-gap: 5px;
  row-gap: 10px;
}
.header {
  font-weight: bold;
  font-size: 120%;
  text-decoration: underline;
}
.notation {
  font-weight: bold;
}
