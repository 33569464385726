:root{
  --imagen-fondo:"";
  --texto-escenas:"black";
  --color-titulo: "black";
  --fuente-titulo: "inherit";
  --fondo-escenas: "rgba(255,255,255,1)";
  --fondo-post:white;
  
  
}


.containerPartida{
  align-items: center;
  width: 50%;
  max-width: 50%;
  min-width: 550px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 13px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

}

.titulo{
  cursor: pointer;
  color: var(--color-titulo);
  font-family: var(--fuente-titulo);
}

.partida{
  background-image: var(--imagen-fondo);
  background-repeat:no-repeat;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

}

.partida > div {
  padding-bottom: 30px;
  min-height: 800px;
}

.listaEscenas {
    list-style: none;
    padding-left: 0;
  }

  .listaEscenas .escena {
    display: flex;
    align-items: center;
    border: solid 1px #d0d0d0;
    border-radius: .2em;
    padding: .5em .8em .5em .5em;
    margin-bottom: 1em;    
    color: var(--texto-escenas);
    background-color: var(--fondo-escenas);
    
  }

  
  .listaEscenas p {
    max-width: none;
    margin: 0;
  }
  
  .listaEscenas-thumb {
    overflow: hidden;
    flex-shrink: 0;
    width: 2em;
    height: 2em;
    background-color: #e8e8e8;
    padding: .5em;
    margin-right: .5em;
  }
  
  .listaEscenas-thumb img {
    display: block;
    width: 100%;
    height: auto;
  }

.listaPosts{
  margin-top: 15px;
  padding-top: 10px;
  background-color: var(--fondo-post);
  border-radius: 5px;
  padding-left: 5px;
}



.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  
}

.pagination div span {
  margin: 0 5px;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s;
  background-color: rgb(207, 207, 207 );
}

.pagination  div span:hover {
  background: #f0f0f0;
}

.pagination span.active {
  background: #000000;
  color: #fff;
}

.pagination div span[disabled] {
  cursor: default;
  color: #999;
}


.arrow-left
{
  flex: 1;
  display: flex;
  justify-content: flex-end; /* Ajusta los elementos hacia la derecha */
}


.arrow-right {
  flex: 1;
  display: flex;
  justify-content: flex; /* Ajusta los elementos hacia la derecha */
}

.center {
  margin: 0 10px; /* Espacio entre el centro y los lados */
}