.dropdown {
    position: fixed;
    bottom: 15%;
    display: inline-block;
    z-index: 20;
  }
  
  .menu {
    /*opacity: 0;*/
    visibility: visible;
    position: absolute;
    bottom: calc(100% - 5px);
    left: -220px;
    background-color: #f5f5f5;
    width: 220px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
   /* transition: opacity 0.3s ease-in-out, visibility 0s linear 0.3s;*/
    z-index: 2;
  }
  
  .arrow {
    display: inline-block;
    padding: 10px;
    font-size: 18px;
    color: #333;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease-in-out;
    position: relative;
    z-index: 1;
  }
  
  .arrow:hover {
    background-color: #ddd;
  }
  
  .arrow span {
    margin-left: 5px;
  }
  
  .arrow.active  {
    transform: rotate(180deg);
    
  }
  
  .menu a {
    display: block;
    color: #333;
    text-decoration: none;
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .dropdown.show .menu {
    opacity: 1;
    visibility: visible;
    /*transition: opacity 0.3s ease-in-out, visibility 0s linear 0s;*/
    left: 0;
    transition: all 1s ease-out;
   
   
  }

  .dropdown:not(.show) .menu {
    left: -220px;
    transition: all 0.5s ease-out;
  }

  