.botonActivo {
    margin: 5px;
    padding: 5px;
    background-color: black;
    color: white;

}

.botonInactivo {

    margin: 5px;
    padding: 5px;
    background-color: white;
    color: black;

}


.botonProhibido{

opacity: 50%;
cursor: not-allowed;

}
