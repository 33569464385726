@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
@import url('https://use.fontawesome.com/releases/v5.14.0/css/all.css');



video::-webkit-media-controls {
  display: none !important;
}

@media screen and (max-height: 630px) {
  html,
  body {
    font-size: 80%;
  }
}

.hidden {
  display: none !important;
}

.width0 {
  width: 0% !important;
}

.width-peer0 {
  width: 45% !important;
}

.width-peer1 {
  width: 45% !important;
}
.width-peer2 {
  width: 30% !important;
}
.width-peer3 {
  width: 28% !important;
}
.width-peer4 {
  width: 28% !important;
}
.width-peer5 {
  width: 28% !important;
}
.width-peer6 {
  width: 25% !important;
}

.width-peer7 {
  width: 25% !important;
}
.width-peer8 {
  width: 25% !important;
}

.width-peer {
  width: 20% !important;
}

.videocall{
  
    text-align: center;
  
    background-color: #94c8d5;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  
}