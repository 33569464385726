.calendar {
    width: 100%;
    border-collapse: collapse;
  }
  
  .calendar th, .calendar td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .calendar th {
    background-color: #f2f2f2;
  }
  
  .personaje-row td {
    height: 30px;
  }

  .date-row {
    background-color: rgba(255,255,255,0.7);
  }
  
  .calendar-highlight {
    background-color: green;
    color: white;
  }

  .recuentoVacio{
    border: none !important; 
  }