:root{
    --color-chat: #04434A;
    --color-chat-transition: var(--color-chat); /* Valor inicial */
    transition: background-color 0.5s; /* Duración de la transición */
}

/* Estilos para el contenedor principal del chat */
.chat-container {
    display: flex;
    visibility: hidden;
    width: 0;
    height: 0;
  }
  
  /* Estilos para el contenedor del widget de chat */
  .chat-widget-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    
  }
  
  .rcw-widget-container{
    visibility: visible;
  }

  /* Estilos para el contenedor de los mensajes del widget de chat */
  .chat-messages-container {
    flex: 1;
    max-height: 300px;
    overflow-y: auto;
    padding: 10px;
    background-color: #fff;
  }
  
  /* Estilos para el widget de chat */
  .rcw-container {
    border: none;
    box-shadow: none;
    margin-bottom: 0;
    display: flex; /* Agregado para utilizar flexbox */
    
  
  }
 


  .rcw-conversation-container{
    min-width: 470px !important;
    z-index: 1;
    
  }
  
  /* Estilos para el área de mensajes del widget de chat */
  .rcw-messages-container {
    padding: 0;
    flex: 1; /* Agregado para utilizar flexbox */
  }
  
  /* Estilos para el cuadro de entrada de texto del widget de chat */
  .rcw-sender {
    border-top: none;
    padding: 10px;
    background-color: #f9f9f9;
    display: flex;
    align-items: center;
  }
  
 /* Estilos para el cuadro de entrada de texto del widget de chat */
 .rcw-response {
    margin-left: 115px;
  }
  
 /* Estilos para el cuadro de entrada de texto del widget de chat */
 .rcw-info {
  background-color:whitesmoke;
  border: 1px solid #444444;
  border-radius: 15px;

  margin-left: 115px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rcw-info span {
  margin: auto;
}
/* .leido {
 
}
*/


 /* Estilos para el cuadro de entrada de texto del widget de chat */
 .noLeido {
  border: 1px solid grey;
}


  /* Estilos para el campo de entrada de texto del widget de chat */
  .rcw-new-message textarea {
    flex: 1;
    border: none;
    outline: none;
    width: 100%;
    height: 35px;
    resize: none;
  }
  
.rcw-header{
  background-color: var(--color-chat-transition) !important;
}


.transition-enabled {
  transition: background-color 2s; /* Duración de la transición */
}

.rcw-header a{
  text-decoration: none;
  color: white;
}


.rcw-launcher{
  background-color:#04434A !important;
}

  /* Estilos para el botón de envío del widget de chat */
  .rcw-send {
    margin-left: 10px;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    background-color: #04434A;
    color: #fff;
    cursor: pointer;
  }
  
  /* Estilos para el botón de cierre del widget de chat */
  .rcw-close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 5px;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }
  /* Estilos para el contenedor de los botones de usuario */
.chat-user-buttons {
    display: grid;
    flex-direction: column;
    align-items: center;
  }
  
  /* Estilos para los botones de usuario */
  .chat-user-buttons button {
    
    margin-bottom: 10px;
    background-color: #f5f5f5;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    color: #333;
    cursor: pointer;
    
  }
  
  .chat-user-buttons button.active {
    background-color: #94C8D5;
    color: #fff;
  }
  
.custom-component-container {
  position: fixed;
  margin-top: 5px;
  margin-left: 5px;
  height: 400px;
  overflow-y: auto;
  opacity: 1;
  background-color: white;
  width: 80px; /* Establece el ancho inicial */
  transition: width 0.3s ease; /* Agrega la animación de transición */
  border-right: 2px solid #ccc;
}

.custom-component-container.expanded {
  width: 400px; /* Establece el ancho expandido */
}

  .MuiAutocomplete-popper{
    z-index: 999999;
    color: red;
    position: relative;
  }

  
  .rcw-client .rcw-message-text {
    background-color: #94C8D5 !important;
    border-bottom-right-radius: 0px !important;
    
  }
  
  
  .rcw-response .rcw-message-text {

    border-bottom-left-radius: 0px !important;
    
  }
  .rcw-message-client {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  
  
  .rcw-message-client .rcw-message-text {
    padding: 10px;
    border-radius: 4px;
    
  }

  
  
  .rcw-message-client .rcw-message-text p {
    margin: 0;
  }
  
.rcw-timestamp {
    float: right !important;
    margin-left: 6px;
  }

  .custom-novedades{
    color: white;
    background-color: red;
    border-radius: 50%;
  }

  .rcw-title{
    cursor: pointer;
  }